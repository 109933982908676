import { useMemo } from 'react';
import {
  useCart,
  useCartCount,
  useCartCheckout,
  useSettings,
} from '@backpackjs/storefront';

import { Svg } from '@snippets/Svg';

export function CartTotals() {
  const cart = useCart();
  const cartCount = useCartCount();
  const { cartCheckout } = useCartCheckout();
  const settings = useSettings();
  const { checkoutText, subtext, subtotalText } = { ...settings?.cart?.totals };

  const discountTitle = cart?.discountAllocations?.[0]?.title || null;

  const discountAmount = useMemo(() => {
    if (!cart?.discountAllocations?.length) return 0;
    const _discountAmount = cart.discountAllocations.reduce(
      (carry, discount) => {
        if (discount?.discountedAmount?.amount) {
          return carry + Number(discount.discountedAmount.amount);
        }
        return carry;
      },
      0
    );
    return Number(_discountAmount || 0);
  }, [cart?.updatedAt]);

  const subtotalAmount = useMemo(() => {
    let amount = cart?.estimatedCost?.subtotalAmount?.amount || '0';
    if (amount > 0 && discountAmount) {
      amount -= discountAmount;
    }

    return parseFloat(amount || '0')
      .toFixed(2)
      .replace('.00', '');
  }, [cart?.updatedAt, discountAmount]);

  const totalBeforeDiscounts = useMemo(() => {
    return cart?.lines
      ?.reduce((carry, line) => {
        const subtotalAmount = line?.estimatedCost?.subtotalAmount?.amount;

        return carry + parseFloat(subtotalAmount);
      }, 0)
      ?.toFixed(2)
      ?.replace('.00', '');
  }, [cart?.updatedAt]);

  const savingsAmount = useMemo(() => {
    return (
      Math.round((totalBeforeDiscounts - subtotalAmount) * 100) / 100
    ).toFixed(2);
  }, [subtotalAmount, totalBeforeDiscounts]);

  return (
    <div
      className={`flex-col gap-4 border-t border-t-border p-4 ${
        cartCount ? 'flex' : 'hidden'
      }`}
      data-comp={CartTotals.displayName}
    >
      <div>
        {parseFloat(totalBeforeDiscounts) > parseFloat(subtotalAmount) && (
          <>
            <div className="flex justify-between">
              <p className="font-bold">Total:</p>
              <p>${totalBeforeDiscounts}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-bold">Savings:</p>
              <p className="text-red">-${savingsAmount}</p>
            </div>
          </>
        )}

        <div className="flex justify-between">
          <p className="font-bold">{subtotalText || 'Subtotal'}:</p>
          <p
            className={
              parseFloat(totalBeforeDiscounts) > parseFloat(subtotalAmount)
                ? 'text-red'
                : ''
            }
          >
            ${subtotalAmount}
          </p>
        </div>

        {discountTitle && discountAmount && (
          <p className="mt-1 flex text-xs">
            <Svg
              className="mr-1 w-4 text-text"
              src="/svgs/discount.svg#discount"
              title="Arrow Left"
              viewBox="0 0 24 24"
            />
            <span>
              Discount ({discountTitle}): -$
              {parseFloat(discountAmount).toFixed(2)}
            </span>
          </p>
        )}

        {subtext && <p className="mt-1 text-xs">{subtext}</p>}
      </div>

      <button
        aria-label="Go to checkout page"
        className="btn-primary w-full"
        onClick={cartCheckout}
        type="button"
      >
        {checkoutText || 'Checkout'}
      </button>
    </div>
  );
}

CartTotals.displayName = 'CartTotals';
